<template>
  <div class="weather-component">
    <div class="weather-container">
        <div v-if="apiResponse">
          <img class="weather-icon" :src="apiResponse.current.condition.icon" />
          <div class="temp-description">
            <div>{{ apiResponse.current.temp_f }} degrees fahrenheit</div>
            <div>---</div>
            <div>{{ apiResponse.current.condition.text }}</div>
          </div>
          <div>
            {{ apiResponse.location.name }}, {{ apiResponse.location.region }}
          </div>
        </div>
        <div v-else class="spinner-border spinner-border-sm">
          LOADING WEATHER...
        </div>
        <div class="search-container">
          <input
            type="text"
            v-model="searchWeather"
            placeholder="Enter zip code..."
          />
        </div>
        <br />
        <div>
          <button class="search-weather-button" @click="getWeather">
            Get Weather
          </button>
          <button @click="testSpinner()" class="btn btn-primary">
            <div v-if="loading" class="spinner-border spinner-border-sm"></div>
            <span v-if="loading">Testing</span>
            <span v-else>Test Spinner</span>
          </button>
        </div>
      </div>    
  </div>
  
</template>

<script>
import apiData from "../data/apiLibrary.json";

export default {
  name: "Weather",
  data() {
    return {
      apiResponse: null,
      api: apiData,
      searchWeather: "19335",
      loading: null
    };
  },
  methods: {
    getWeather() {
      this.loading = true;
      const axios = require("axios");
      let options = this.api.weatherApi;
      console.log("Options: ");
      console.log(options);
      options.params.q =
        this.searchWeather != ""
          ? this.searchWeather
          : this.makeRandomZipCode();
      console.log("options.params.q: ");
      console.log(options.params.q);
      axios
        .request(options)
        .then((response) => {
          this.apiResponse = response.data;
          this.loading = false;
          console.log("apiResponse data: ");
          console.log(this.apiResponse.current);
        })
        .catch(function (error) {
          console.log("Error: ");
          console.log(error);
        });
    },
    makeRandomZipCode() {
      return Math.round(Math.random(0, 1) * 100000);
    },
    testSpinner() {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 2000)
    }
  },
  mounted() {
    this.getWeather();
  },
};
</script>

<style>
:root {
  --r: 255;
  --g: 0;
  --b: 0;
  --text-color: rgb(var(--r), var(--g), var(--b));
}
.weather-component {
  display: flex;
  justify-content: center;
}
.weather-container {
  width: clamp(200px, 50%, 600px);
}
.temp-description {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
