<template>
    <!-- only display if an event has fired off. Otherwise, it doesn't have event data to display and throws an error -->
    <div v-if="event">
        <h1>{{ event.title }}</h1>
        <p>{{ event.time }} on {{ event.date }} @ {{ event.location }}</p>
        <p>{{ event.description }}</p>        
    </div>

</template>

<script>
// import { defineComponent } from '@vue/composition-api'
import EventService from '../services/EventServices.js'
export default {
    props: ['id'],
    data() {
        return {
            event: null
        }
    },
    created() {
        // fetch event (by id) and set local data above
        EventService.getEvent(this.id) //'id' comes from the 'props' referenced in EventCard.vue line 3
        .then(response => {
            this.event = response.data // set 'event' in data
        })
        .catch(error => {
            console.log(error)
        })        
    }
}
</script>
