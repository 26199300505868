<template>
  <div class="events">
    <h1>Events For Good</h1>
    <!-- the ":event" binding looks to be pointing at the "event" prop in EventCard.vue -->
    <!-- so we're taking the "event" object from the for loop below and passing that data to the EventCard.vue  -->
    <EventCard v-for="event in events" :key="event.id" :event="event" />
  </div>
</template>

<script>
// @ is an alias to /src
import EventCard from '@/components/EventCard.vue'
import EventService from '../services/EventServices.js'

export default {
  name: 'EventList',
  components: {
    EventCard
  },
  data() {
    return {
      events: null
    }
  },
  // created == lifecycle hook
  // i.e. when our component is created, we want to call out to the server
  // for those events
  created() {
    // axios.get(
    //   'https://my-json-server.typicode.com/Code-Pop/Real-World_Vue-3/events'
    // )
    EventService.getEvents()
    .then(response => { // .then == what to do when the request has been answered
      this.events = response.data // takes the JSON response from the internet and assigns it to the "events" data above
      console.log('events:', response.data)
    })
    .catch(error => { // .catch == what to do if something goes wrong
      console.log(error)
    })
  }
}
</script>

<style scoped>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
