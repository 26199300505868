<template>
  <div class="app">
    <nav class="navbar">
      <ul class="navbar-nav">
        <li class="nav-item">
          <router-link :to="{ name: 'EventList' }" class="nav-link">
            <img src="@/images/calendar.svg" alt="Events" class="navbar-icon" />
            <span class="link-text">Events</span>              
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'About' }" class="nav-link">
            <img src="@/images/person.svg" alt="About" class="navbar-icon" />
            <span class="link-text">About</span>            
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'Weather' }" class="nav-link">
            <img src="@/images/weather.svg" alt="Weather" class="navbar-icon" />
            <span class="link-text">Weather</span>          
          </router-link>
        </li>
        <!-- <li>
          <Login>
            <img src="@/images/login.svg" alt="Login"  class="navbar-icon">
            <span class="link-text">Login</span>
          </Login>
        </li> -->
      </ul>
    </nav>
    <div class="main">
      <router-view /> 
    </div>

  </div>
</template>
<script>

</script>
<style>
:root {
  font-size: 16px;
  font-family: 'Open Sans';
  --text-primary: red;
  --text-secondary: #ececec;
  --bg-primary: green;
  --bg-secondary: #141418;
}
.app {
  color: black;
  background-color: white;
  margin: 0;
  padding: 0;
}
.app::-webkit-scrollbar {
  width: 0.25rem;
}
.app::-webkit-scrollbar-track {
  background: #1e1e24;
}
.app::-webkit-scrollbar-thumb {
  background: #6649b8;
}
.main {
  margin-left: 5rem;
  padding: 1rem;
}
.navbar {
  position: fixed;
  background-color: var(--bg-primary);
  transition: width 200ms ease;
}
.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.navbar:hover {
  width: 12rem;
}
.navbar:hover .link-text {
  display: block;
}
.nav-item {
  width: 100%;
}
.nav-item:last-child {
  margin-top: auto;
}
.nav-link {
  display: flex;
  align-items: center;
  height: 5rem;
  color: var(--text-primary);
  text-decoration: none;
}
.link-text {
  display: none;
  margin-left: 1rem;
}
.nav-link img {
  min-width: 2rem;
  max-width: 2rem;
  margin: 0 1.5rem;  
}
.navbar-icon {
  color: #ff7eee;
}
/* .nav a {
  font-weight: bold;
  color: #2c3e50;
}

.nav a.router-link-exact-active {
  color: #42b983;
} */

h4 {
  font-size: 20px;
}
/* Small screens */
@media only screen and (max-width: 600px) {
  .navbar {
    bottom: 0;
    width: 100vw;
    height: 5rem;
  }
  .navbar-nav {
    flex-direction: row;    
  }
  .nav-link {
    justify-content: center;
  }
  main {
    margin: 0;
  }
}
/* Large screens */
@media only screen and (min-width: 600px) {
  .navbar {
    width: 5rem;
    height: 100vh;
    top: 0;
  }
  .navbar:hover {
    width: 16rem;
  }
  .navbar:hover .link-text {
    display: inline;
    transition: opacity var(--transition-speed);;
  }
}
  
</style>
